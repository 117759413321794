import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CoreFacade } from '../../core.facade';
import { Router } from '@angular/router';
import { AuthFacade } from 'app/auth/auth.facade';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { MenuItemDto } from 'app/core/models/menu-item-dto';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss']
})
export class CoreComponent implements OnInit, OnDestroy {
  title = 'Aimtec DEEP';
  menuItems: MenuItem[] = [];
  userItems: MenuItem[] | undefined;
  actualRoute?: string;

  loading: boolean = true;
  menuItemsLoaded: boolean = true;
  userItemsLoaded: boolean = true;

  private subscriptions: Subscription[] = [];

  constructor(private coreFacade: CoreFacade) {
    this.title = $localize`Aimtec DEEP`;
  }

  ngOnInit(): void {
    this.loading = true;

    this.coreFacade.init();
    this.actualRoute = window.location.pathname;

    this.coreFacade.loadMenuItems();

    this.subscriptions.push(this.coreFacade.menuItems$.subscribe(menuItems => {
      this.menuItems = menuItems;
      this.menuItemsLoaded = true;

      if(this.userItemsLoaded)
        this.loading = false;
    }));

    this.subscriptions.push(this.coreFacade.userItems$.subscribe(userItems => {
      this.userItems = userItems;
      this.userItemsLoaded = true;

      if(this.menuItemsLoaded)
        this.loading = false;
    }));    
  }  

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
