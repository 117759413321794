import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ShowErrorComponent } from './containers/show-error/show-error.component';
import { CoreComponent } from './containers/core/core.component';
import { SharedRoutes } from '@shared/shared.routes';
import { PolicyType } from 'app/auth/models/policy-type'
import { RoleType } from 'app/auth/models/role-type'
import { AuthGuard } from 'app/auth/guards/auth.guard';

export const baseTitle: string = $localize` | Aimtec DEEP`;

export const coresRoutes: Routes = [
  {
    path: '',
    component: CoreComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: SharedRoutes.homeRoute.name
        // data: {
        //   access: [
        //     {
        //       policiesAccess: [],
        //       rolesAccess: [RoleType.Administrator_Full]
        //     },
        //     {
        //       policiesAccess: [PolicyType.ASP_Reader],
        //       rolesAccess: []
        //     },
        //     { 
        //       policiesAccess: [],
        //       rolesAccess: [ RoleType.Checks_Item ]
        //     } 
        //   ],
        // }
      },
      {
        path: SharedRoutes.homeRoute.name,
        loadChildren: () => import('@modules/home/home.module').then(m => m.HomeModule),
        canActivate: [MsalGuard],
        title: SharedRoutes.homeRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.reportsRoute.name,
        loadChildren: () => import('@modules/report/report.module').then(m => m.ReportModule),
        canActivate: [MsalGuard],
        title: SharedRoutes.reportsRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.errorWithCodeRoute.name,
        component: ShowErrorComponent,
        canActivate: [MsalGuard],
        title: SharedRoutes.errorWithCodeRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.errorRoute.name,
        component: ShowErrorComponent,
        canActivate: [MsalGuard],
        title: SharedRoutes.errorRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.apiAccessRoute.name,
        loadChildren: () => import('@modules/api-access/api-access.module').then(m => m.ApiAccessModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.apiAccessRoute.titleName + baseTitle,
        data: {
          access: [
            {
              policiesAccess: [],
              rolesAccess: [RoleType.Administrator_Full]
            },
            {
              policiesAccess: [],
              rolesAccess: [RoleType.ApiAccess_Default]
            },
          ],
        }
      },
      {
        path: SharedRoutes.configurationRoute.name,
        loadChildren: () => import('@modules/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.configurationRoute.titleName + baseTitle,
        data: {
          access: [
            {
              policiesAccess: [],
              rolesAccess: [RoleType.Administrator_Full]
            },
            {
              policiesAccess: [],
              rolesAccess: [RoleType.Configurations]
            },
          ],
        }
      },
      {
        path: SharedRoutes.dataCheckSettingsRoute.name,
        loadChildren: () => import('@modules/data-check/setting/setting.module').then(m => m.SettingsModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.dataCheckSettingsRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.dataCheckReportRoute.name,
        loadChildren: () => import('@modules/data-check/report/report.module').then(m => m.ReportModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.dataCheckReportRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.dataCheckRoute.name,
        loadChildren: () => import('@modules/data-check/home/home.module').then(m => m.HomeModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.dataCheckRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationRoute.name,
        loadChildren: () => import('@modules/integration/run/run.module').then(m => m.RunModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationManualRunRoute.name,
        loadChildren: () => import('@modules/integration/run/run.module').then(m => m.RunModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationManualRunRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationBomRoute.name,
        loadChildren: () => import('@modules/integration/import-data/bom/bom.module').then(m => m.BomModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationBomRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationCalendarRoute.name,
        loadChildren: () => import('@modules/integration/import-data/calendar/calendar.module').then(m => m.CalendarModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationCalendarRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationCustomerRoute.name,
        loadChildren: () => import('@modules/integration/import-data/customer/customer.module').then(m => m.CustomerModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationCustomerRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationInventoryRoute.name,
        loadChildren: () => import('@modules/integration/import-data/inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationInventoryRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationItemRoute.name,
        loadChildren: () => import('@modules/integration/import-data/item/item.module').then(m => m.ItemModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationItemRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationManufacturingRoute.name,
        loadChildren: () => import('@modules/integration/import-data/manufacturing/manufacturing.module').then(m => m.ManufacturingModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationManufacturingRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationOperationRoute.name,
        loadChildren: () => import('@modules/integration/import-data/operation/operation.module').then(m => m.OperationModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationOperationRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationOutputRoute.name,
        loadChildren: () => import('@modules/integration/import-data/output/output.module').then(m => m.OutputModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationOutputRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationPurchaseRoute.name,
        loadChildren: () => import('@modules/integration/import-data/purchase/purchase.module').then(m => m.PurchaseModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationPurchaseRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationResourceRoute.name,
        loadChildren: () => import('@modules/integration/import-data/resource/resource.module').then(m => m.ResourceModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationResourceRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationRoutingRoute.name,
        loadChildren: () => import('@modules/integration/import-data/routing/routing.module').then(m => m.RoutingModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationRoutingRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationSaleRoute.name,
        loadChildren: () => import('@modules/integration/import-data/sale/sale.module').then(m => m.SaleModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationSaleRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationShiftRoute.name,
        loadChildren: () => import('@modules/integration/import-data/shift/shift.module').then(m => m.ShiftModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationShiftRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationSubresourceRoute.name,
        loadChildren: () => import('@modules/integration/import-data/subresource/subresource.module').then(m => m.SubresourceModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationSubresourceRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationOperationExportRoute.name,
        loadChildren: () => import('@modules/integration/export-data/operation-export/operation-export.module').then(m => m.OperationExportModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationOperationExportRoute.titleName + baseTitle
      },
      {
        path: SharedRoutes.integrationOrderExportRoute.name,
        loadChildren: () => import('@modules/integration/export-data/order-export/order-export.module').then(m => m.OrderExportModule),
        canActivate: [MsalGuard, AuthGuard],
        title: SharedRoutes.integrationOrderExportRoute.titleName + baseTitle
      },
      {
        path: "**",
        redirectTo: "error",
      }
    ]
  },
];

export class CoreRoutingModule { }
